
import { computed, defineComponent, reactive, ref } from "vue";
import ProfileCard from "@/app/private/pages/profile/components/ProfileCard.vue";
import BaseCard from "@/app/core/components/cards/BaseCard.vue";
import BaseCheckbox from "@/app/core/components/forms/BaseCheckbox.vue";
import BaseFormGroup from "@/app/core/components/forms/BaseFormGroup.vue";
import BaseInput from "@/app/core/components/forms/BaseInput.vue";
import BaseFileUpload from "@/app/core/components/forms/BaseFileUpload.vue";
import BaseButton from "@/app/core/components/buttons/BaseButton.vue";
import BaseIcon from "@/app/core/components/BaseIcon.vue";
import { useProfileModule } from "@/store/modules/ProfileModule";
import { AchContract } from "@/app/core/interfaces/services/billing/AchContract";
import { CashappContract } from "@/app/core/interfaces/services/billing/CashappContract";
import { ZelleContract } from "@/app/core/interfaces/services/billing/ZelleContract";
import { CheckContract } from "@/app/core/interfaces/services/billing/CheckContract";
import { BillingService } from "@/app/core/services/BillingService";
import { container } from "tsyringe";
import { PaymentTypeEnum } from "@/app/core/enums/PaymentTypeEnum";
import { useToast } from "vue-toastification";
import { DocumentService } from "@/app/core/services/DocumentService";
import { AchUpdateContract } from "@/app/core/interfaces/services/billing/AchUpdateContract";
import { determineFileType } from "@/app/core/utils/determineFileType";
import { downloadFile } from "@/app/core/utils/restHelpers";
import { VenmoContract } from "@/app/core/interfaces/services/billing/VenmoContract";

export default defineComponent({
  name: "MyBilling",
  components: {
    BaseIcon,
    BaseButton,
    BaseFileUpload,
    BaseInput,
    BaseFormGroup,
    BaseCheckbox,
    BaseCard,
    ProfileCard,
  },
  async setup() {
    const billingService: BillingService = container.resolve(BillingService);
    const documentService: DocumentService = container.resolve(DocumentService);
    const toast = useToast();
    const profileCtx = useProfileModule();
    const updatingVoidCheck = ref<boolean>(false);

    if (!profileCtx.state.billing) {
      profileCtx.actions.fetchBilling();
    }

    if (!profileCtx.state.documents) {
      await profileCtx.actions.fetchDocuments();
    }

    if (!profileCtx.state.paymentProfiles) {
      await profileCtx.actions.fetchPaymentProfiles();
    }

    const selectedProfiles = reactive({
      ach: !!profileCtx.getters.getAch,
      check: !!profileCtx.getters.getCheck,
      zelle: !!profileCtx.getters.getZelle,
      cashapp: !!profileCtx.getters.getCashapp,
      invoice: !!profileCtx.getters.getInvoice,
      quickpay: !!profileCtx.getters.getQuickPay,
    });

    const updateAchForm = reactive<AchUpdateContract>({
      routing: (profileCtx.getters.getAch?.info as AchContract).routing,
      account: (profileCtx.getters.getAch?.info as AchContract).account,
      file: undefined,
    });

    const updateAch = () => {
      billingService
        .updatePayment(PaymentTypeEnum.ACH, {
          routing: updateAchForm.routing,
          account: updateAchForm.account,
        })
        .then(() => {
          const formData = new FormData();
          formData.append("voidCheck", updateAchForm.file!);
          documentService.update(formData).then(() => {
            toast.success("Successfully updated ACH account");
            profileCtx.actions.fetchDocuments();
            updatingVoidCheck.value = false;
            updateAchForm.file = undefined;
          });
        });
    };

    const updateCashappForm = reactive<CashappContract>({
      username: (profileCtx.getters.getCashapp?.info as CashappContract).username,
    });

    const updateCashapp = () => {
      billingService.updatePayment(PaymentTypeEnum.Cashapp, updateCashappForm).then(() => {
        toast.success("Successfully updated Cashapp account");
      });
    };

    const updateVenmo = () => {
      billingService.updatePayment(PaymentTypeEnum.Venmo, updateVenmoForm).then(() => {
        toast.success("Successfully updated Venmo account");
      });
    };

    const updateVenmoForm = reactive<VenmoContract>({
      email: (profileCtx.getters.getVenmo?.info as VenmoContract).email,
    });

    const updateZelleForm = reactive<ZelleContract>({
      email: (profileCtx.getters.getZelle?.info as ZelleContract).email,
    });

    const updateZelle = () => {
      billingService.updatePayment(PaymentTypeEnum.Zelle, updateZelleForm).then(() => {
        toast.success("Successfully updated Zelle account");
      });
    };

    const updateCheckForm = reactive<CheckContract>({
      address: (profileCtx.getters.getCheck?.info as CheckContract).address,
    });

    const updateCheck = () => {
      billingService.updatePayment(PaymentTypeEnum.Check, updateCheckForm).then(() => {
        toast.success("Successfully updated Check account");
      });
    };

    const downloadDocument = (id: number) => {
      toast.info("Downloading file");
      documentService.downloadDocument(id).then((res) => {
        downloadFile(res.file, res.originalName);
      });
    };

    return {
      selectedProfiles,
      updateAchForm,
      updateAch,
      updateCashappForm,
      updateCashapp,
      updateZelleForm,
      updateZelle,
      updateCheckForm,
      updateCheck,
      updateVenmo,
      updateVenmoForm,
      updatingVoidCheck,
      determineFileType,
      downloadDocument,
      currentVoidCheck: computed(() => profileCtx.getters.getVoidCheck),
      billing: computed(() => profileCtx.getters.getBilling),
    };
  },
});
